import { useAuthStore } from '~/store/auth.js'

export const useSubscriptionStore = defineStore('subscription', {
  state: () => ({
    data: null,
  }),
  actions: {
    async getSubscription() {
      const { $pinia } = useNuxtApp()
      const config = useRuntimeConfig()
      const store = useAuthStore($pinia)

      const subscription = await getSubscriptionDetails(
        store.user.org_id,
        config.public.apiBaseUrl,
        store.token,
      )

      const data = {
        status: subscription.data?.organization.status ?? null,
        days_until_due: subscription.data?.organization.past_due_date ?? null,
        current_period_end: subscription.data?.current_period_end ?? null,
        cancel_subscription: subscription.data?.cancel_subscription ?? null,
      }

      this.data = data

      return data
    },
  },
})

const getSubscriptionDetails = async (org_id, apiBaseUrl, token) => {
  return await $fetch(`${apiBaseUrl}/subscription`, {
    method: 'GET',
    params: {
      organizationId: org_id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}
